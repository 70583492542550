<template>
  <div>

    <!-- Info -->
    <vx-card title="ลูกค้าธนาคาร SCB">
    <div>
    <div v-for="(step, indexScb) of bankTypeScb" :key="indexScb">
    <h4 class="mb-2">ลำดับที่ {{ indexScb+1 }}</h4>

    <vs-radio v-for="(choiceScb, indexScb2) of bankListScb" :key="indexScb2" v-model="bankTypeScb[indexScb]" :vs-value="choiceScb" class="mr-4" :vs-name= "'scb' + choiceScb " >{{ choiceScb }}</vs-radio>

  </div>

  ผลลัพธ์การแสดง SCB
  <p :key="keyScb" v-for="(resultScb,index, keyScb) in bankTypeScb" > {{(index+1) + '.' +resultScb }}</p>
  </div>
    </vx-card>
  <br>

  <vx-card title="ลูกค้าธนาคาร KBANK">
  <div>
    <div v-for="(step, indexKbank) of bankTypeKbank" :key="indexKbank">
    <h4 class="mb-2">ลำดับที่ {{ indexKbank+1 }}</h4>

    <vs-radio v-for="(choiceKbank, indexKbank2) of bankListKbank" :key="indexKbank2" v-model="bankTypeKbank[indexKbank]" :vs-value="choiceKbank" class="mr-4" :vs-name= "'kbank' + choiceKbank " >{{ choiceKbank }}</vs-radio>

  </div>
   ผลลัพธ์การแสดง KBANK
   <p :key="keyKbank" v-for="(resultKbank,index, keyKbank) in bankTypeKbank" > {{(index+1) + '.' +resultKbank }}</p>

  </div>
    </vx-card>
  <br>

  <vx-card title="ลูกค้าธนาคารอื่นๆ">
  <div>
    <div v-for="(step, indexOther) of bankTypeOther" :key="indexOther">
    <h4 class="mb-2">ลำดับที่ {{ indexOther+1 }}</h4>

    <vs-radio v-for="(choiceOther, indexOther2) of bankListOther" :key="indexOther2" v-model="bankTypeOther[indexOther]" :vs-value="choiceOther" class="mr-4" :vs-name= "'other' +choiceOther " >{{ choiceOther }}</vs-radio>
    <br>
  </div>
    <!-- Save & Reset Button -->
    ผลลัพธ์การแสดง ธนาคารอื่นๆ
    <p :key="keyOther" v-for="(resultOther,index, keyOther) in bankTypeOther" > {{(index+1) + '.' +resultOther }}</p>
  </div>
    </vx-card>
  <br>

  <vx-card title="ลูกค้า VIP (special)">
  <div>
    <div v-for="(step, indexSpecial) of bankTypeSpecial" :key="indexSpecial">
    <h4 class="mb-2">ลำดับที่ {{ indexSpecial+1 }}</h4>

    <vs-radio v-for="(choiceSpecial, indexSpecial2) of bankListSpecial" :key="indexSpecial2" v-model="bankTypeSpecial[indexSpecial]" :vs-value="choiceSpecial" class="mr-4" :vs-name= "'Special' +choiceSpecial " >{{ choiceSpecial }}</vs-radio>
  </div>
    <!-- Save & Reset Button -->
    ผลลัพธ์การแสดง ธนาคารลูกค้า VIP
    <p :key="keySpecial" v-for="(resultSpecial,index, keySpecial) in bankTypeSpecial" > {{(index+1) + '.' +resultSpecial }}</p>
  </div>
  </vx-card>
  <br>
  <vx-card title="ตั้งชื่อรูปแบบการแสดงธนาคาร">
  <vs-input class="w-full "  label-placeholder="ตั้งชื่อรูปแบบการแสดงธนาคาร" v-model="name" name="name"></vs-input>
  <br>
  <div class="flex flex-wrap items-center justify-end ">
      <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" class="ml-auto mt-2" color="success" @click="Save" >บันทึก</vs-button>
    </div>
  </vx-card>
  </div>
</template>

<script>
import axios from '../../../src/axios'

import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      status:false,
      name:'',
      getData :'',
      getDataid :'',
      template:{'scb':[],
        'kbank':[],
        'other':[],
        'special':[]},
      bankListScb:[],
      bankTypeScb:[],
      bankListKbank:[],
      bankTypeKbank:[],
      bankListOther:[],
      bankTypeOther:[],
      bankListSpecial:[],
      bankTypeSpecial:[]
    }
  },
  async mounted () {
    await axios
      .get('bankshow/getchoice')
      .then(response => (this.getData = response.data))
    await axios
      .get(`bankshow/editlist/${this.$route.params.id}`)
      .then(response => (this.getDataid = response.data))
    this.name = this.getDataid[0].name
    //SCB
    this.bankListScb = JSON.parse(JSON.stringify(this.getData))
    const stringsToDeleteSCB = ['kbankonly', 'scball']
    for (const stringScb of stringsToDeleteSCB) {
      const indexToDeleteSCB = this.bankListScb.indexOf(stringScb)

      if (indexToDeleteSCB !== -1) {
        this.bankListScb.splice(indexToDeleteSCB, 1)
      }
    }
    this.bankTypeScb = (this.getDataid[0].template['scb'])

    //KBANK
    this.bankListKbank = JSON.parse(JSON.stringify(this.getData))
    const stringsToDeleteKBANK = ['kbankall', 'scbonly']

    for (const string of stringsToDeleteKBANK) {
      const indexToDelete =  this.bankListKbank.indexOf(string)

      if (indexToDelete !== -1) {
        this.bankListKbank.splice(indexToDelete, 1)

      }
    }
    this.bankTypeKbank = (this.getDataid[0].template['kbank'])

    //OTHER
    this.bankListOther = JSON.parse(JSON.stringify(this.getData))
    const stringsToDeleteOther = ['kbankonly', 'scbonly']

    for (const string of stringsToDeleteOther) {
      const indexToDelete =  this.bankListOther.indexOf(string)

      if (indexToDelete !== -1) {
        this.bankListOther.splice(indexToDelete, 1)
      }
    }
    this.bankTypeOther = (this.getDataid[0].template['other'])

    //Special
    this.bankListSpecial = JSON.parse(JSON.stringify(this.getData))
    this.bankTypeSpecial = (this.getDataid[0].template['special'])
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async Save () {
      this.template['scb'] = (this.bankTypeScb)
      this.template['kbank'] = (this.bankTypeKbank)
      this.template['other'] = (this.bankTypeOther)
      this.template['special'] = (this.bankTypeSpecial)
      console.log(this.template)
      await axios
        .post('bankshow/edittemplate', {
          id:this.$route.params.id,
          name:this.name,
          template:this.template
        }).then(response => (this.status = response.data))
      if (this.status.status === true) {

        this.$vs.notify({
          time:3000,
          color:'success',
          position:'top-right',
          icon: 'check_box',
          title: this.status.title

        })

        setTimeout(() => { window.location.reload() }, 1500)
      } else {
        this.$vs.notify({
          time:8000,
          color: 'danger',
          position:'top-right',
          icon:  'error',
          title:this.status.title,
          text: this.status.info
        })
      }


    },
    Reset () {
      this.bankTypeScb = [null, null, null, null, null]
      this.bankTypeKbank = [null, null, null, null, null]
      this.bankTypeOther = [null, null, null, null, null]
      this.bankTypeSpecial = [null, null, null, null, null]
    //  setTimeout(() => {  window.location.reload() }, 1000)
    }

  }
}

</script>
